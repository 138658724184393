import React, { Component } from 'react'
import {connect} from 'react-redux';
import {signIn, signOut } from '../../../actions';

import firebase from "firebase"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"

class FbLogin extends Component {
    state = { isSignedIn: false }

  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //   firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    //   firebase.auth.GithubAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccess: () => {console.log('signinsuccess')}
    }
  }

  componentDidUpdate = () => {
      console.log('firebsae', firebase.auth())
    firebase.auth().onAuthStateChanged(user => {
      if(user){
        this.props.signIn(user)
        console.log("user", user)
      }else{
          this.props.signOut()
      }
    })
  }

    onSignInClick = () => {
        firebase.auth().signIn()
    }
    onSignOutClick = () => {
        firebase.auth().signOut()
    }
  

    render() {
        return (
            <div>
            {this.props.isSignedIn ? (
          <span>
            <button onClick={this.onSignOutClick}>Sign out!</button>
          </span>
        ) : (
          <StyledFirebaseAuth
            uiConfig={this.uiConfig}
            firebaseAuth={firebase.auth()}
          />
        )}
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    console.log('gblogin state', state)
    return{ 
        isSignedIn: state.auth.isSignedIn,
    }
}

export default connect(
    mapStateToProps, 
    {signIn, signOut})
    (FbLogin)
