const validate = values => {
    const errors = {}
    if (!values.firstName) {
      errors.firstName = 'Required'
    }
    if (!values.lastName) {
      errors.lastName = 'Required'
    }
    if (!values.country) {
      errors.country = 'Required'
    }
    if (!values.age) {
      errors.age = 'Required'
    } else if (isNaN(Number(values.age))) {
      errors.age = 'Must be a number'
    } else if (Number(values.age) < 10 || Number(values.age) >100) {
      errors.age = ' must be between 10-100'
    }
    if (!values.gender) {
      errors.gender = 'Required'
    }
    if (!values.degree) {
      errors.degree = 'Required'
    }
    if (values.termsAndConditions != "I agree to the terms and conditions") {
      errors.termsAndConditions = 'Required'
    }
    return errors
  }
  
  export default validate