import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';


import findMentor from "./assest/findMentor.svg";
import remoteSchool from "./assest/remoteSchool.svg";

class Home extends Component {

    
    render() {
        const {isSignedIn, loading } = this.props.auth

        return (
            <div> 
                Home 
                {/* <div className="image" >
                    <img style={{maxWidth:'400px'}}
                    src={findMentor} />
                </div>
                <div className="image" >
                    <img style={{maxWidth:'400px'}}
                    src={remoteSchool} />
                </div> */}
            </div>
        )
        
    }
}
const mapStateToProps = (state) => {
    console.log('state:', state)
    
    return { 
        auth:state.auth,
        mentors:state.mentors
    }
}

export default connect(mapStateToProps, { })(Home);
