import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

// function submit(values) {
//   return sleep(1000) // simulate server latency
//     .then(() => {
//       if (![ 'john', 'paul', 'george', 'ringo' ].includes(values.username)) {
//         throw new SubmissionError({ username: 'User does not exist', _error: 'Login failed!' })
//       } else if (values.password !== 'redux-form') {
//         throw new SubmissionError({ password: 'Wrong password', _error: 'Login failed!' })
//       } else {
//         window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`)
//       }
//     })
// }
const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <label>{label}</label>
    <div>
      <input {...input} placeholder={label} type={type}/>
      {touched && error && <span>{error}</span>}
    </div>
  </div>
)

const RegisterForm = (props) => {
  const { error, handleSubmit, pristine, reset, submitting } = props
  return (
    <form onSubmit={handleSubmit}>
      <Field name="email" type="text" component={renderField} label="Email"/>
      <Field name="password" type="password" component={renderField} label="Password"/>
      {error && <strong>{error}</strong>}
      <div>
        <button type="submit" disabled={submitting}>Register</button>
        <button type="button" disabled={pristine || submitting} onClick={reset}>Clear Values</button>
      </div>
    </form>
  )
}
const validate = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = 'Required'
  }
  if (!values.lastName) {
    errors.lastName = 'Required'
  }
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.sex) {
    errors.sex = 'Required'
  }
  if (!values.favoriteColor) {
    errors.favoriteColor = 'Required'
  }
  return errors
}

export default reduxForm({
  form: 'registerForm',
  validate 
})(RegisterForm)