import { combineReducers } from 'redux';
//we are importing reducer ang giving a name as fromReducer below
import { reducer as formReducer} from 'redux-form';
import authReducer from './authReducer';
import lessonReducer from './lessonReducer';
import { firestoreReducer } from 'redux-firestore';
import mentorsReducer from './mentorsReducer';
import menteesReducer from './menteeReducer';
import eventReducer from './eventReducer';


export default combineReducers ({
    auth:authReducer,
    form: formReducer,
    lessons: lessonReducer,
    mentors:mentorsReducer,
    mentees:menteesReducer,
    events:eventReducer,
    firestore:firestoreReducer,
}); 