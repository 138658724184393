import React, { Component } from 'react'
import _ from 'lodash'

import { connect } from 'react-redux';
import { createMentor, fetchMentees, editMentee} from '../../../actions';

import  MentorForm from './mentorForm/MentorForm';
import { ModalHeader,Modal,ModalBody, ModalFooter, Button} from 'reactstrap';

class MentorCreate extends Component {

    state={
        isApplyModalOpen:false
    }

    componentDidMount (){
        const {userId}=this.props.match.params
        this.props.fetchMentees(`/mentees/?userId=${userId}`)
    }
    componentDidUpdate(pP){
        const {mentors, isCreated, mentee} = this.props
        console.log('previous',pP)
        // if(mentors.length != pP.mentors.length){
        //     console.log('successfull')
        //     this.setState({isApplyModalOpen:true})
        // }
        if(pP.mentee){
            if(mentee.appliedForMentor != pP.mentee.appliedForMentor){
                console.log('successfull')
                this.setState({isApplyModalOpen:true})
            }
        }
        
    }
    

    onMentorSubmit = (formValues) => {
        // handleSubmit itself calls event and  event.preventDefault();
        //formValues takes mentor input that entered field directly
        const { mentee:{firstName, lastName, age, country, degree, 
            employed, interests, googleInfo, gender, userId, id} } = this.props
        const mentorId = (Math.random()*100000000000000000).toString()
        const role = "candidatementor"
        this.props.createMentor({...formValues, firstName, lastName, age, country, degree, 
            employed, interests, googleInfo, gender, mentorId, userId, role});
         
        this.props.editMentee(id, {appliedForMentor:true})
        // console.log("new apply input:" , {...formValues, firstName, lastName, age, country, degree, 
        //     employed, interests, googleInfo, gender, mentorId, userId, role})
    }
    renderMentorForm() {
        const {isSignedIn, mentee} = this.props
        
        if(isSignedIn && mentee.appliedForMentor){
            return(
                <div className="ui warning message" style={{textAlign:'center'}} >
                    <div className="header">
                        We have your application
                    </div>
                        Please, wait for a response from us.
                </div>
            )
        }
        else if(isSignedIn && !mentee.appliedForMentor){
        return (
            <React.Fragment>
                <MentorForm 
                onSubmit={this.onMentorSubmit} />
            </React.Fragment>
        )
        }else {
            return(
                <div className="ui warning message" style={{textAlign:'center'}} >
                    <div className="header">
                        You must sig in before you can do that!
                    </div>
                        Visit our registration page, then try again
                </div>
            )
        }
    } 

    render() {
        console.log('apply props', this.props)
        return(
            <React.Fragment>
                <div>
                    {this.renderMentorForm()}
                </div>
                <div>
                <Modal isOpen={this.state.isApplyModalOpen} >
                    <ModalHeader>Successfull</ModalHeader>
                    <ModalBody>
                        We have your application, you will be informed anytime soon.
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => this.props.history.push('/')} color="success">Return Home</Button>
                    </ModalFooter>
                </Modal>
                </div>
                
            </React.Fragment>
        )

        
         
}
}
const mapStateToProps = (state, ownProps) => {
    console.log('apply state', state)
    return{ 
        isSignedIn: state.auth.isSignedIn,
        currentUser: state.auth.currentUser,
        mentee:state.mentees[ownProps.match.params.userId],
        mentors: Object.values(state.mentors),
        isCreated:state.mentors.isCreated,
    }
}

export default connect(mapStateToProps, 
    { createMentor, fetchMentees, editMentee})
    (MentorCreate);

