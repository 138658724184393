import React from 'react'
import { Field, reduxForm } from 'redux-form'
import validate from './validate'
import renderField from './renderField'

const renderError = ({ meta: { touched, error } }) => touched && error ?
  <span>{error}</span> : false

const WizardFormSecondPage = (props) => {
  const { handleSubmit, previousPage } = props
  return (
    <form 
    className="ui form error"
    onSubmit={handleSubmit}>
      <Field name="age" type="number" component={renderField} label="Age"/>
      <div>
        <label>Gender</label>
        <div>
          <div>
            <label><Field name="gender" component="input" type="radio" value="male"/> Male</label>
          </div>
          <div>
            <label><Field name="gender" component="input" type="radio" value="female"/> Female</label>
          </div>
          <Field name="gender" component={renderError}/>
        </div>
      </div>
      <div>
        <button className="ui labeled icon button" type="button"  onClick={previousPage}>
          <i class="left arrow icon"></i>Previous</button>
        <button className="ui labeled icon button" type="submit" >
          Next <i class="right arrow icon"></i></button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'menteewizard',  //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
  validate
})(WizardFormSecondPage)