import React from 'react'
import { Field, FieldArray, reduxForm, Errors } from 'redux-form'
import {Link} from 'react-router-dom'



const renderField = ({ input, label, type, placeholder,meta: { touched, error } }) => (
  <div className= {`field ${error && touched ? 'error': '' }`}>
    <label>{label}</label>
    <div>
      <input {...input} type={type} placeholder={placeholder} />
      {touched && error && <span>{error}</span>}
    </div>
  </div>
)


const renderSpeciality = ({ fields, meta: { error, submitFailed } }) => (
  <ul>
    <li>
      <button  className="ui positive basic button" type="button" onClick={() => fields.push({})}>
        Add Speciality
      </button>
      {submitFailed && error && <span>{error}</span>}
    </li>
    {fields.map((speciality, index) => (
      <li key={index}>
        <button className="ui negative basic button"
          type="button"
          onClick={() => fields.remove(index)}
        >Remove</button>
        <h4>Speciality - {index + 1}</h4>
        <Field
          name={`${speciality}.title`}
          type="text"
          component={renderField}
          label="Title"
        />
        <Field
          name={`${speciality}.details`}
          type="text"
          component={renderField}
          label="Details"
        />
      </li>
    ))}
  </ul>
)
const renderEducations = ({ fields, meta: { error, submitFailed } }) => (
  <ul>
    <li>
      <button  className="ui positive basic button" type="button" onClick={() => fields.push({})}>
        Add School
      </button>
      {submitFailed && error && <span>{error}</span>}
    </li>
    {fields.map((education, index) => (
      <li key={index}>
        <button className="ui negative basic button"
          type="button"
          onClick={() => fields.remove(index)}
        >Remove</button>
        <h4>School - {index + 1}</h4>
        <Field
          name={`${education}.school`}
          type="text"
          component={renderField}
          label="School"
        />
        <Field
          name={`${education}.area`}
          type="text"
          component={renderField}
          label="Area"
        />
      </li>
    ))}
  </ul>
)



const MentorForm = props => {
  const { handleSubmit, pristine, reset, submitting } = props
  return (
    <div className="container">
    <form 
    className="ui form error"
      onSubmit={handleSubmit}>
      <div className="field">
        <label>About Me</label>
        <Field name="aboutMe" component='textarea' label="About Me"
        placeholder="Please tell us about yourself"
        />
      </div>
      <div className="field">
        <label>Educational Background</label>
        <FieldArray name="educations" component={renderEducations} />
      </div>
      <div className="field">
        <label>You Specialities</label>
        <FieldArray name="specialities" component={renderSpeciality} />
      </div>
      <div>
        <Field name="termsAndConditions" type="text" 
          component={renderField} label="Please type: I agree to the terms and conditions"
          placeholder="I agree to the terms and conditions"/>
        <div>
            <Link to='/termsandconditions' >Terms and Conditios</Link>
        </div>
      </div>
      <div>
        <button className="ui button primary" type="submit" disabled={submitting} >
          Submit
        </button>
        <button className="ui green button" type="button" disabled={pristine || submitting} onClick={reset}>
          Clear Values
        </button>
      </div>
    </form>
    </div>
  )
}
const validate = values => {
    const errors = {}
    if (!values.educations) {
      errors.educations = 'Required'
    }
    if (!values.specialities) {
      errors.specialities = 'Required'
    }
    if (!values.aboutMe) {
      errors.aboutMe = 'Required'
    }
    if (values.termsAndConditions != "I agree to the terms and conditions") {
      errors.termsAndConditions = 'Required'
    }
    return errors
  }

export default reduxForm({
  form: 'mentorForm', // a unique identifier for this form
  validate
})(MentorForm)


