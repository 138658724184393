import React, { Component } from 'react'

import { connect } from 'react-redux';
import { fetchLessons} from '../../../actions';

import {Modal, ModalHeader, ModalBody} from "reactstrap";

import FullCalendar,{ formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction"

class MentorCalendar extends Component {
    state={
        showEventModal:false,
        selectEvent:null,
        lastCheched:null,

    }


    toggleEvent = () =>{
        this.setState({showEventModal:!this.state.showEventModal})
    }

    
    handleEventClick = (selectEvent) => {
        console.log(selectEvent)
        this.setState({
            selectEvent:selectEvent.event._def,
            showEventModal:true
        })
    }
    renderEventModalBody = () => {
      const {selectEvent:{extendedProps:{details, acceptStatus, userId, explanation, mentorFullName}}}= this.state 
      //let color=acceptStatus ==="booked" ?"green" : acceptStatus ==="denied" ? "red" : "gray"
      return(
          <React.Fragment>
              <div className="ui middle aligned divided list">
                <div className="item">
                    <i className="user icon"></i>
                    <div className="content">
                        <div className="header">Details</div>
                            <div className="description">{details}</div>
                    </div>
                </div>
                <div className="item">
                    <i className="user icon"></i>
                    <div className="content">
                        <div className="header">Status</div>
                            <div className="description">{acceptStatus}</div>
                    </div>
                </div>
                <div className="item">
                    <i className="user icon"></i>
                    <div className="content">
                        <div className="header">Mentor</div>
                            <div className="description">{mentorFullName}</div>
                    </div>
                </div>
                <div className="item">
                    <i className="user icon"></i>
                    <div className="content">
                        <div className="header">Explanation</div>
                            <div className="description">{explanation}</div>
                    </div>
                </div>
              </div>
      </React.Fragment>
      )
    }
    refreshCalendar = () =>{
        const {userId}=this.props.mentee
        const today = new Date()
        const lastCheched = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        this.props.fetchLessons(`/lessons/?userId=${userId}`)
        this.setState({lastCheched})
    }


    render() {
        console.log('state', this.state.availabilities)
        //console.log('props', this.props)
        const {selectEvent}= this.state
        const {mentee:{availabilities}} = this.props
        return (
            <React.Fragment>
                    <div className="sixteen wide column">
                        <div className="sixteen wide column">
                            <div onClick={this.refreshCalendar} style={{cursor:'pointer'}} >
                                <div className="ui red ribbon label">
                                    Refresh Calendar
                                    <i className="sync icon"></i> 
                                    {this.state.lastCheched}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="sixteen wide column">
                        <FullCalendar 
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        initialView='dayGridMonth'
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        nowIndicator={true}
                        select={this.handleDateSelect}
                        selectAllow={this.selectAllow}
                        events={this.props.lessons}
                        eventClick={this.handleEventClick}
                        />
                    </div>
                    {selectEvent ? 
                    <Modal isOpen={this.state.showEventModal} toggle={this.toggleEvent}>
                            <ModalHeader>{selectEvent.title}</ModalHeader>
                            <ModalBody>
                                {this.renderEventModalBody()}
                            </ModalBody>
                    </Modal>
                    : null }
            </React.Fragment>
        )
    }
}

export default  connect(null, {fetchLessons})(MentorCalendar)
