import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchLessons} from '../../actions';

class LessonsList extends React.Component {
    
    componentDidMount ()  {
        this.props.fetchLessons('/lessons/?acceptStatus=booked');
        //console.log("fetchLessons:", this.props.fetchLessons())
    }

    //we are defining a helper method to aviod mess in renderList
    //admin can delete and edit, if the userId matches with currentUserId
    renderAdmin (lesson) {
        const {id, createdBy} = lesson
        if(this.props.isSignedIn && createdBy.userId === this.props.currentUser.userId) {
            return (
                <div className="right floated content">
                    <Link to={`/lessons/edit/${id}`} className="ui button primary">
                        Edit
                    </Link>
                    <Link to={`/lessons/delete/${id}`} className="ui button negative">
                        Delete
                    </Link>
                </div>
            )
        }
        return (
            <div className="right floated content" data-tooltip={`Sing in as: ${createdBy.userName}`}>
            <div className="ui gray button">
                Edit
            </div>
            <div className="ui gray button">
                Delete
            </div>
        </div>
        )
    }
    renderList (lessons) {
        //console.log("lessons array:", lessons)
        const activeLessons = _.filter(lessons, {acceptStatus:"booked"})
        return activeLessons.map(lesson => {
            const {createdBy, title, description, date, time,id} = lesson
            return (
            <div className="column" key={id}>
                <div  className="ui fluid card"   >
                    <Link to={`/room/${id}`} className="content" style={{cursor:'pointer'}}>
                        <h2 className="header">{title} </h2>
                    </Link>
                    <div className="extra content">
                    {description}
                    </div>
                    <div className="center aligned content">
                        <span >{date} |</span>
                        <span >{time}</span>
                    </div>
                    <div className="center aligned content">
                        <h3 className="meta"> Added by:</h3> 
                        <div className="ui image label">
                            <img  src={createdBy.imageUrl} alt='no image' />{createdBy.userName}
                        </div>
                    </div>
                    <div className="center aligned content">
                        {this.renderAdmin(lesson)}
                    </div>
                </div>
            </div>
            )

        })
    }

    
    render () {
        //console.log('list props', this.props.lessons)
        if(this.props.lessons){
            return (
                <React.Fragment>
      
                  {/* <Header /> */}
      
                  <div className="ui container"style={{minHeight:'80vh'}} >
                      <h2>Lessons</h2>
                      <div className="ui grid">
                          <div className="doubling four column row">
                          {this.renderList(this.props.lessons)}
                      </div>
                  </div>
                  </div>
      
              </React.Fragment>
            )  
        }
        return(
            <div>Loading</div>
        )
        
      
    }
}



const mapStateToProps = (state) => {
    console.log('state:', state)
    //since lessons is an obj. we turn it into an array with Object.values
    return { 
        lessons: state.lessons,
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn,
    }
}

export default connect(mapStateToProps, {fetchLessons })(LessonsList) 