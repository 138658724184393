import React, { useState } from 'react';

import _ from 'lodash';
import {Link} from 'react-router-dom';

import {MenteeProfileCard} from './MenteeProfileCard';



const MenteeCardTabs = (props) => {
  const [activeTab, setActiveTab] = useState('2');

  const toggleTab = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  
      const renderLessonList = ( ) => {
        // console.log('lessons', lessons)
        // console.log('mentorId', mentorId)

        const bookedLessons = _.filter(props.lessons, {acceptStatus:"booked"})
        return bookedLessons.map(lesson => {
            const {title, mentorFullName, start, time,id, acceptStatus,explanation} = lesson
            const stasusColor = acceptStatus === "booked" ? "success" : acceptStatus === "denied" ? "danger" : "secondary"
            // const now = new Date()
            // console.log('now', typeof(now))
            // console.log('now', now)
            // const isLessonOpen = now <= start ? true : false
            // {now > start ? console.log('true') : console.log('false')}
            return (
                <tbody key={id}>
                    <tr>
                        <td>
                            {title}
                        </td>
                        <td>
                            {mentorFullName}
                        </td>
                        <td>
                            {start}
                        </td>
                        <td>
                            <h3>{acceptStatus}</h3>
                        </td>
                        <td>
                            {explanation}
                        </td>
                        <td>
                        <Link className="ui green button" to={`/room/${id}`}>Go to Lesson</Link>
                        </td>   
                    </tr>
                </tbody>

            )
        })
    }
    const {mentee, mentee:{userId}}= props
  //console.log('menteecard', mentee)
  return (
    <div className="sixteen wide column">
      <div class="ui top attached tabular menu">
        <div  
        className={`item ${activeTab === '1' ? "active": ""}`} 
        onClick={() => { toggleTab('1'); }}
        data-tab="Profile">
        <i className="user circle icon"></i> Profile</div>
        
        <div 
        className={`item ${activeTab === '2' ? "active": ""}`}
        onClick={() => { toggleTab('2'); }}
        data-tab="Booked Lessons">
        <i className="calendar alternate icon"></i>Booked Lessons</div>
        <div 
        className={`item ${activeTab === '3' ? "active": ""}`}
        onClick={() => { toggleTab('3'); }}
        data-tab="third">
        <i className="add icon"></i> Third</div>
      </div>
      <div className={`ui bottom attached tab segment ${activeTab === '1' ? "active": ""}`} data-tab="Profile">
          <MenteeProfileCard 
            mentee={mentee}
          />
          <Link to={`/mentee/profile/update/${userId}`} className="ui olive button">Update</Link>
      </div>
      <div className={`ui bottom attached tab segment ${activeTab === '2' ? "active": ""}`} data-tab="Booked Lessons">
        <table className="ui celled table" >
          {renderLessonList()}
          <thead >
              <tr><th>Title</th>
              <th>Mentor</th>
              <th>Booking Time</th>
              <th>Status</th>
              <th>Explanation</th>
              <th>Action</th>
          </tr></thead>
        </table>
      </div>
      <div className={`ui bottom attached tab segment ${activeTab === '3' ? "active": ""}`} data-tab="third">
        Third
      </div>
    </div>
  );
}

export default MenteeCardTabs;