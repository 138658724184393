import React from 'react'
import { Field, reduxForm } from 'redux-form'
import {Link} from 'react-router-dom'

import Multiselect from 'react-widgets/lib/Multiselect'
import 'react-widgets/dist/css/react-widgets.css'

import validate from './validate'
import renderField from './renderField'



const degress = [ "Associate's Degrees", "Bachelor's Degrees","Master's Degrees", "Doctoral Degrees","None" ]

const dataInterest=[ 'IT', 'Language', 'Literature', 'Philosophy', 'Gaming', 'Traveling', 'Reading',
'Child Care', 'Pet Care', 'Music','Cooking','Collecting', 'Marketing', 'Learning languages', 'Photography',]

const renderColorSelector = ({ input, meta: { touched, error } }) => (
  <div className= {`field ${error && touched ? 'error': '' }`}>
    <select {...input}>
      <option value="">Select Your Degree...</option>
      {degress.map(val => <option value={val} key={val}>{val}</option>)}
    </select>
    {touched && error && <span>{error}</span>}
  </div>
)

const renderMultiselect = ({ input, valueField, textField }) =>
  <Multiselect {...input}
    onBlur={() => input.onBlur()}
    value={input.value || []} // requires value to be an array
    data={dataInterest}
    valueField={valueField}
    textField={textField}
  />
  
const WizardFormThirdPage = (props) => {
  const { handleSubmit, pristine, previousPage, submitting } = props
  return (
    <form 
    className="ui form error"
    onSubmit={handleSubmit}>
      <div>
        <label>Degree</label>
        <Field name="degree" component={renderColorSelector}/>
      </div>
      <div>
        <label htmlFor="employed">Employed</label>
        <div>
          <Field name="employed" id="employed" component="input" type="checkbox"/>
        </div>
      </div>
      <div>
        <label>Interests</label>
        <div>
        <Field
          name="interests"
          component={renderMultiselect}
          />
        </div>
      </div>
      <div>
        <div>
          <Field name="termsAndConditions" type="text" 
          component={renderField} label="Please type: I agree to the terms and conditions"
          placeholder="I agree to the terms and conditions"/>
        </div>
        <div>
            <Link to='/termsandconditions' >Terms and Conditios</Link>
        </div>
      </div>
      <div>
      <button className="ui labeled icon button" type="button"  onClick={previousPage}>
          <i class="left arrow icon"></i>Previous</button>
        <button className="ui green button" type="submit" disabled={pristine || submitting}>Submit</button>
      </div>
    </form>
  )
}
export default reduxForm({
  form: 'menteewizard', //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
  validate
})(WizardFormThirdPage)