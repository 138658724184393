import React, { Component } from 'react'

import { connect } from 'react-redux';
import _ from 'lodash';
import {fetchMentees, editMentee} from '../../../actions';

import MenteeUpdateForm from './menteeForm/menteeWizardForm/MenteeUpdateForm';


class MenteeProfileUpdate extends Component {

    componentDidMount (){
     const {userId}=this.props.match.params
    this.props.fetchMentees(`/mentees/?userId=${userId}`)
    }

    onSubmit= (formValues) => {
        const {mentee:{id, userId}} = this.props
        this.props.editMentee(id, formValues)
        console.log('edit', formValues)
        this.props.history.push(`/mentee/profile/${userId}`)
    }
    render() {
        const {mentee}=this.props
        if(!mentee){
            return(
                <div>Loading</div>
            )
        }
        return (
            <div className="ui container">
                <div className="ui green segment">
                <MenteeUpdateForm
                onSubmit={this.onSubmit}
                initialValues={_.pickBy(mentee)}
                />
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    // console.log('mentee pofile state', state)
     //const lessons = _.filter(state.lessons, {userId:ownProps.match.params.userId})
     //console.log('filter lessons', lessons)
     return {
         mentee:state.mentees[ownProps.match.params.userId],
     }
 
 }
 export default connect(mapStateToProps, 
     {fetchMentees, editMentee})(MenteeProfileUpdate)
