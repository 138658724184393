import React, { PureComponent } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import esLocale from 'moment/locale/es';
import moment from 'moment';
import { Field } from 'redux-form';


class DatePicker extends PureComponent {
  constructor(props) {
    super(props);
    moment.locale('tr', esLocale);
  }

  state = {
    focused: false,
  };

  onFocusChange = value => {
    //console.log('focus value', value)
    this.setState({ focused: !this.state.focused });
    const { input } = this.props;
    input.onFocus(value);
  };

  render() {
    //console.log('date picker',this.props)
    //console.log('moment', moment())
    const {
      input,
      meta: { touched, error, warning },
      placeholder,
      disabled,
      required,
      label
    } = this.props;
    const { focused } = this.state;
    const invalid = error !== undefined && error !== null;

    return (
      <div className="field">
        <label>{label}</label>
        <SingleDatePicker
          showClearDate={true}
          showDefaultInputIcon={false}
          displayFormat="YYYY-MM-DD"
          numberOfMonths={1}
          disabled={disabled}
          placeholder={placeholder}
          date={input.value}
          onDateChange={input.onChange}
          focused={focused}
          onFocusChange={this.onFocusChange}
          id={input.name}
        />
        {error && touched && <span>{error}</span>}
      </div>
    );
  }
}

export const formatDates = value => (value ? moment(value) : null);

export const normalizeDates = value =>
  value ? value.format('YYYY-MM-DD') : null;

const normalizeTime = (value, previousValue) => {
    if (!value) {
      return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
      // typing forward
      if (onlyNums.length === 2) {
        return onlyNums + ':'
      }
      if (onlyNums.length === 4) {
        return onlyNums.slice(0, 2) + ':' + onlyNums.slice(2)
      }
    }
    if (onlyNums.length <= 2) {
      return onlyNums
    }
    if (onlyNums.length <= 4) {
      return onlyNums.slice(0, 2) + ':' + onlyNums.slice(2)
    }
    return onlyNums.slice(0, 2) + ':' + onlyNums.slice(2, 4)
  }
export const FieldDatePicker = props => {
  return (
    <React.Fragment>
    <Field
      normalize={normalizeDates}
      format={formatDates}
      name={props.name}
      placeholder={props.placeholder}
      component={DatePicker}
      props={props}
    />
    </React.Fragment>
  );
};


export default DatePicker;
