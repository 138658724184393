import React from 'react'
import { Field, reduxForm } from 'redux-form'

import Multiselect from 'react-widgets/lib/Multiselect'
import 'react-widgets/dist/css/react-widgets.css'

import validate from './validate'
import renderField from './renderField'

const degress = [ "Associate's Degrees", "Bachelor's Degrees","Master's Degrees", "Doctoral Degrees","None" ]

const dataInterest=[ 'IT', 'Language', 'Literature', 'Philosophy', 'Gaming', 'Traveling', 'Reading',
'Child Care', 'Pet Care', 'Music','Cooking','Collecting', 'Marketing', 'Learning languages', 'Photography',]


const renderError = ({ meta: { touched, error } }) => touched && error ?
  <span>{error}</span> : false

const renderColorSelector = ({ input, meta: { touched, error } }) => (
    <div className= {`field ${error && touched ? 'error': '' }`}>
      <select {...input}>
        <option value="">Select Your Degree...</option>
        {degress.map(val => <option value={val} key={val}>{val}</option>)}
      </select>
      {touched && error && <span>{error}</span>}
    </div>
  )
  
const renderMultiselect = ({ input, valueField, textField }) =>
    <Multiselect {...input}
      onBlur={() => input.onBlur()}
      value={input.value || []} // requires value to be an array
      data={dataInterest}
      valueField={valueField}
      textField={textField}
/>
const MenteeUpdateForm = (props) => {
  const { handleSubmit,pristine, submitting, reset } = props
  return (
    <form 
    className="ui form error"
    onSubmit={handleSubmit}>
      <Field name="firstName" type="text" component={renderField} label="First Name"/>
      <Field name="lastName" type="text" component={renderField} label="Last Name"/>
      <Field name="country" type="text" component={renderField} label="Country"/>
      <Field name="age" type="number" component={renderField} label="Age"/>
      <div>
        <label>Gender</label>
        <div>
          <div>
            <label><Field name="gender" component="input" type="radio" value="male"/> Male</label>
          </div>
          <div>
            <label><Field name="gender" component="input" type="radio" value="female"/> Female</label>
          </div>
          <Field name="gender" component={renderError}/>
        </div>
      </div>
      <div>
        <label>Degree</label>
        <Field name="degree" component={renderColorSelector}/>
      </div>
      <div>
        <label htmlFor="employed">Employed</label>
        <div>
          <Field name="employed" id="employed" component="input" type="checkbox"/>
        </div>
      </div>
      <div>
        <label>Interests</label>
        <div>
        <Field
          name="interests"
          component={renderMultiselect}
          />
        </div>
      </div>
      <div>
        <button className="ui green  button" type="submit" disabled={pristine || submitting}>Submit</button>
        <button className="ui green button" type="button" disabled={pristine || submitting} onClick={reset}>Clear Values</button>
      </div>
    </form>
  ) 
}

export default reduxForm({
  form: 'menteeupdate',                 
  validate
})(MenteeUpdateForm)