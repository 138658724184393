import React, { Component } from 'react'
import _ from 'lodash';
import { Table, Modal, ModalBody, ModalHeader,Button, 
    ModalFooter, Badge, Jumbotron, Container, Col, Row,
Card, CardBody, CardText, CardSubtitle, CardImg, CardTitle,
TabContent, TabPane, Nav, NavItem, NavLink,
Toast, ToastBody, ToastHeader
} from "reactstrap";
import classnames from 'classnames';

import { connect } from 'react-redux';
import {fetchMentor,fetchMentors, fetchLessons, createLesson, editLesson, deleteLesson } from '../../../actions'
import {MenteeEditDeleteLesson, MenteeCreateLesson} from './MenteeAction';

import GoogleAuth from '../../auth/GoogleAuth';
import {MentorProfileCard} from './mentorProfile/MentorProfileCard';
import MentorCardCalendar from './MentorCardCalendar';


class MentorCard extends Component {
    constructor (props) {
        super(props);
        this.state= {
            isModalOpen:false,
            activeTab:'1'
        };

    }

    componentDidMount () {
        //this.props.fetchMentors(`/?mentorId=${this.props.match.params.mentorId}`)
        this.props.fetchLessons(`/lessons/?mentorId=${this.props.match.params.mentorId}`)
    }
    toggleModal= () =>{ //we use setState directly in onClick below
        this.setState({
            isModalOpen: !this.state.isModalOpen
        });
    }
    toggleTab = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState({activeTab:tab})
        }
    }
    

    render() {
        //console.log('mentorcard props',this.props)
        const {mentor, lessons, isSignedIn} = this.props
        if(!mentor || !lessons){
            return (
                <div> Loading</div>
            )
        }
        return (
            <div className="ui container">
            <div className="sixteen wide column">
                <div class="ui top attached tabular menu">
                    <div  
                    className={`item ${this.state.activeTab === '1' ? "active": ""}`} 
                    onClick={() => { this.toggleTab('1'); }}
                    data-tab="Profile">
                    <i className="user circle icon"></i> Profile</div>
                    
                    <div 
                    className={`item ${this.state.activeTab === '2' ? "active": ""}`}
                    onClick={() => { this.toggleTab('2'); }}
                    data-tab="Comments">
                    <i className="calendar alternate icon"></i>Second</div>
                    <div 
                    className={`item ${this.state.activeTab === '3' ? "active": ""}`}
                    onClick={() => { this.toggleTab('3'); }}
                    data-tab="third">
                    <i className="add icon"></i> Third</div>
                </div>
                <div className={`ui bottom attached tab segment ${this.state.activeTab === '1' ? "active": ""}`} data-tab="Profile">
                    <MentorProfileCard 
                        mentor={mentor}
                    />
                </div>
                <div className={`ui bottom attached tab segment ${this.state.activeTab === '2' ? "active": ""}`} data-tab="Comments">
                </div>
                <div className={`ui bottom attached tab segment ${this.state.activeTab === '3' ? "active": ""}`} data-tab="third">
                    Third
                </div>
            </div>
            <div className="sixteen wide column">
                {isSignedIn ? 
                        <MentorCardCalendar
                            lessons={this.props.lessons}
                            mentor={this.props.mentor}
                            currentUser={this.props.currentUser}
                        />
                :
                <div className="ui yellow segment">
                <div class="ui icon message">
                    <i class="sign in icon"></i>
                    <div class="content">
                        <div class="header">
                        Please sign in to see mentor calendar
                        </div>
                    </div>
                    <div className="ui vertical divider">Sig In</div>
                    <div className="content">
                        <GoogleAuth/>
                    </div>
                </div>
                </div>
                }
            </div>
            </div>
        )

    }
}
const mapStateToProps = (state, ownProps) => {
    //console.log('userCard', state)
    const lessons = _.filter(state.lessons, {mentorId:ownProps.match.params.mentorId})
    return {
        mentor:state.mentors[ownProps.match.params.mentorId],
        isSignedIn: state.auth.isSignedIn,
        currentUser: state.auth.currentUser,
        lessons: Object.values(lessons),
        //lessons:Object.values(state.lessons),
    }

}
export default connect(
    mapStateToProps, 
    {fetchMentor, fetchMentors,fetchLessons, createLesson, editLesson, deleteLesson})
(MentorCard)
