import _ from 'lodash';
import { CREATE_MENTEE, 
    FETCH_MENTEES,FETCH_MENTEE,
    DELETE_MENTEE, EDIT_MENTEE } from '../actions/types';



    export default (state = {loading:true}, action) => {
        switch (action.type) {
            case FETCH_MENTEES:
                //_mapKeys turns array into an object taking 'id' as a key
                //it takes action.payload array and create an obj. 
                //  ... adds them into new object
                //return { ...state, ...action.payload}
                return { ...state, ..._.mapKeys(action.payload, 'userId'), loading:false};
            case FETCH_MENTEE:
                //key-value pair in state object, find [id]:its pair
                return { ...state, [action.payload.userId]:action.payload, loading:false};
            case CREATE_MENTEE:
                return {...state, [action.payload.userId]:action.payload, loading:false};
            case EDIT_MENTEE:
                return { ...state, [action.payload.userId]:action.payload, loading:false};
            case DELETE_MENTEE:
                //for DELETE_MENTEE, action.payload is id itself (from action creator)
                //omit doesnt change the original state,  it create a new obj. insted
                return _.omit(state, action.payload)
            
            default:
                return state;
        }
    }
