import React, { Component } from 'react'
import _ from 'lodash';
import {Link} from 'react-router-dom';


import { connect } from 'react-redux';
import {fetchMentor, fetchLessons, editLesson} from '../../../../actions';

import MentorCalendar from './MentorCalendar';
import  MentorCardTabs  from './MentorCardTabs';


class MentorProfile extends Component {

    componentDidMount (){
     const {mentorId}=this.props.match.params
    //  this.props.fetchMentor(`/mentors/?mentorId=${mentorId}`)
    this.props.fetchLessons(`/lessons/?mentorId=${mentorId}`)
    }
    componentDidUpdate(pP){
        const {auth:{currentUser, isSignedIn}}=this.props
        if(isSignedIn === false){
            this.props.history.push('/')
        }
    }

    toggleTab = (tab) => {
        if(this.state.activeTab !== tab){
            this.setState({activeTab:tab})
        }
    }


    renderMentor = () => {
        const {mentor, mentor:{mentorId, userId}, lessons} = this.props
        const lessonsAsMentor = Object.values(_.filter(lessons, {mentorId}))
        const lessonsAsMentee = Object.values(_.filter(lessons, {userId}))
        return(
            <div className="ui container">
                <div>
                    <MentorCardTabs
                    mentor={mentor}
                    lessonsAsMentor={lessonsAsMentor}
                    lessonsAsMentee={lessonsAsMentee} />
                </div>
                <div>
                    <MentorCalendar
                        allessons={lessons}
                        lessonsAsMentor={lessonsAsMentor}
                        lessonsAsMentee={lessonsAsMentee}
                        mentor={this.props.mentor}
                        currentUser={this.props.currentUser}
                    />
                </div>
            </div>
        )
    }
    render() {
        //console.log('mentor state', this.state)
        const {mentor, lessons} = this.props
        if(!mentor){
            return(
                <div>Loading</div>
            )
        }
        return (
            <React.Fragment>
                {this.renderMentor()}
            </React.Fragment>

                
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    console.log('mentor state', state)
    // const mentors = _.mapKeys(state.mentors.mentors, 'mentorId')
    //console.log('mentors', mentors)
    // const lessons = _.filter(state.lessons, {mentorId:ownProps.match.params.mentorId})
    return {
        mentor:state.mentors[ownProps.match.params.mentorId],
        lessons: state.lessons,
        auth:state.auth,
    }

}
export default connect(mapStateToProps, { fetchMentor, fetchLessons, editLesson})(MentorProfile)
