import React, { Component } from 'react'
import { connect } from 'react-redux';
import { fetchMentee, fetchMentees } from '../../actions';
import LoginEmail from './LoginEmail'
import GoogleAuth from '../auth/GoogleAuth'
import MenteeCreate from '../user/mentee/MenteeCreate';

class LoginMain extends Component {
    state={
        currentMentee:null,
    }

    componentDidUpdate(pP){
        console.log('update pP',pP)
        console.log('update this props',this.props)
        const {isSignedIn, currentUser }= this.props.auth
        if(isSignedIn && pP.auth.currentUser !== currentUser){

            console.log('this.componentDidUpdate')
            this.props.fetchMentees(`/mentees/?userId=${this.props.auth.currentUser.userId}`)
            this.props.history.push('/')
        }
            
        
    }
    renderMenteeForm=() => {
        return(
            <React.Fragment>
                <MenteeCreate/>
            </React.Fragment>
        )
    }

    checkLogin=()=>{
        const {auth:{isSignedIn, loading}}=this.props
        if(!isSignedIn){
            return(
            <div style={{background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)'}} >
                <div>
                    <LoginEmail/>
                </div>
            </div>
            )
        }
        // else if(isSignedIn && !this.props.mentees ){
        //     return (
        //         <React.Fragment>
        //             <MenteeCreate/>
        //         </React.Fragment>
        //     )
        // } else{
        //     return(
        //         this.props.history.push('/allmentors')
        //     )
        // }
        
    }
    render() {
        
        return (
            <React.Fragment>
                {this.checkLogin()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    console.log('state:', state)
    
    return { 
        auth:state.auth,
        mentors:state.mentors,
        mentees:state.mentees,
    }
}

export default connect(mapStateToProps, {fetchMentee, fetchMentees })(LoginMain);