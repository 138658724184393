import React from 'react'

export const  MentorProfileCard = (props) => {
    const {firstName, lastName, country, age,  aboutMe, googleInfo, specialities, education } = props.mentor
    return(
        <React.Fragment>
        <div className="ui red segment" style={{backgroundColor:'olive'}} >
            <div className="ui stackable two column grid">
                <div className="six wide column">
                <div  className="ui fluid card">
                    <img className="ui large image" src={googleInfo.imageUrl} />
                        <h2 className="header">{firstName} {lastName} </h2>
                    <div className="center aligned content">
                        {googleInfo.userEmail}
                    </div>
                    <div className="center aligned content">
                        <i className={`${country} flag`}></i> {country} 
                    </div>
                    <div className="center aligned content">
                        {age}
                    </div>
                    {/* <div className="extra content">
                        opdate {props.updateMentor}
                    </div> */}
                </div>
                </div>
                <div className="ten wide column">
                    <div className="ui horizontal divider header">
                        <i className="info icon"></i> About me
                    </div>
                    <p>{aboutMe} </p>
                    <div className="ui horizontal divider header">
                        <i className="graduation cap icon"></i> Education
                    </div>
                    <div  className="ui horizontal divided list">
                            {education.map((school) =>{
                                return (
                                    <div key={school.title} className="item">
                                        <i className="map marker icon"></i>
                                        <div className="top aligned content">
                                            <div className="header">{school.title}</div>
                                            <div className="middle aligned content">
                                                {school.details}
                                            </div>
                                            <div className="buttom aligned content">
                                                {school.degree}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                    <div className="ui horizontal divider header">
                        <i className="lightbulb icon"></i> Specialities
                    </div>
                    <div  className="ui horizontal divided list">
                            {specialities.map((speciality) =>{
                                return (
                                    <div key={speciality.title} className="item">
                                        <i className="star icon"></i>
                                        <div className="top aligned content">
                                            <div className="header">{speciality.title}</div>
                                            <div className="middle aligned content">
                                                {speciality.details}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>

                </div>
            </div>
        </div>
            </React.Fragment>
    )
}
