import React, { useState } from 'react';

import _ from 'lodash';
import {Link} from 'react-router-dom';

import { MentorProfileCard } from './MentorProfileCard';



const MentorCardTabs = (props) => {
    console.log('props', props)
  const [activeTab, setActiveTab] = useState('2');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

    const renderAsMentorLessonList = () => {
    // console.log('lessons', lessons)
    // console.log('mentorId', mentorId)
    const bookedLessons = _.filter(props.lessonsAsMentor, {acceptStatus:"booked"})
    return bookedLessons.map(lesson => {
        const {title, userId, start, id, acceptStatus, explanation} = lesson
        return (
            <tbody key={id}>
                <tr>
                    <td>
                        {title}
                    </td>
                    <td>
                        {userId}
                    </td>
                    <td>
                        {start}
                    </td>
                    <td>
                        <h3>{acceptStatus}</h3>
                    </td>
                    <td>
                        {explanation}
                    </td>
                    <td>
                    <Link className="ui green button" to={`/room/${id}`}>Go to Lesson</Link>
                    </td>
                </tr>
            </tbody>

        )})
    } 
    const renderAsMenteeLessonList = () => {
        // console.log('lessons', lessons)
        // console.log('mentorId', mentorId)
        const bookedLessons = _.filter(props.lessonsAsMentee, {acceptStatus:"booked"})
        return bookedLessons.map(lesson => {
            const {title, mentorFullName, start, id, acceptStatus, explanation} = lesson
            return (
                <tbody key={id}>
                    <tr>
                        <td>
                            {title}
                        </td>
                        <td>
                            {mentorFullName}
                        </td>
                        <td>
                            {start}
                        </td>
                        <td>
                            <h3>{acceptStatus}</h3>
                        </td>
                        <td>
                            {explanation}
                        </td>
                        <td>
                        <Link className="ui green button" to={`/room/${id}`}>Go to Lesson</Link>
                        </td>
                    </tr>
                </tbody>
    
            )})
    }
    
    const {mentor, mentor:{availabilities, mentorId}}= props
  //console.log('menteecard', mentee)
  return (
    <div className="sixteen wide column">
      <div class="ui top attached tabular menu">
        <div  
        className={`item ${activeTab === '1' ? "active": ""}`} 
        onClick={() => { toggle('1'); }}
        data-tab="Profile"> 
        <i className="user circle icon"></i> Profile</div>
        
        <div 
        className={`item ${activeTab === '2' ? "active": ""}`}
        onClick={() => { toggle('2'); }}
        data-tab="Mentor Lessons">
        <i className="calendar alternate icon"></i>Mentor Lessons</div>
        <div 
        className={`item ${activeTab === '3' ? "active": ""}`}
        onClick={() => { toggle('3'); }}
        data-tab="Mentee Lessons">
        <i className="calendar alternate outline icon"></i>Mentee Lessons</div>
        <div 
        className={`item ${activeTab === '4' ? "active": ""}`}
        onClick={() => { toggle('4'); }}
        data-tab="Availabilities">
        <i className="braille icon"></i>Availabilities</div>
      </div>
      <div className={`ui bottom attached tab segment ${activeTab === '1' ? "active": ""}`} data-tab="Profile">
          <MentorProfileCard 
            mentor={mentor}
            
          />
          <Link to={`/mentor/profile/update/${mentorId}`} className="ui olive button">Update</Link>
      </div>
      <div className={`ui bottom attached tab segment ${activeTab === '2' ? "active": ""}`} data-tab="Mentor Lessons">
        <table className="ui celled table" >
          {renderAsMentorLessonList()}
          <thead >
              <tr><th>Title</th>
              <th>Mentee</th>
              <th>Booking Time</th>
              <th>Status</th>
              <th>Explanation</th>
              <th>Action</th>
          </tr></thead>
        </table>
      </div>
      <div className={`ui bottom attached tab segment ${activeTab === '3' ? "active": ""}`} data-tab="Mentee Lessons">
        <table className="ui celled table" >
          {renderAsMenteeLessonList()}
          <thead >
              <tr><th>Title</th>
              <th>Mentor</th>
              <th>Booking Time</th>
              <th>Status</th>
              <th>Explanation</th>
              <th>Action</th>
          </tr></thead>
        </table>
      </div>
      <div className={`ui bottom attached tab segment ${activeTab === '4' ? "active": ""}`} data-tab="Availabilities">
          <div className="ui green segment">
          <p> To set your availabilities
            first go for a blank page in calendar and select your availabile times on a daily basis.
            Your availabilities will be appliying for each week. 
            Slots that outside of your availabile times are shown as grayed out.
            Remember you may update your availabilities anytime.</p>
            <div className="ui large horizontal divided list">
                {availabilities.map(day =>{
                    return (
                        
                            <div className="item">
                                <div className="top aligned content">{day.day} </div>
                                <div className="middle aligned content">{day.startTime}</div>
                                <div className="bottom aligned content">{day.endTime}</div>
                            </div>
                    
                    )
                })}
             </div>
            
          </div>
      </div>
    </div>
  );
}

export default MentorCardTabs;
