import { 
    CREATE_EVENT, DELETE_EVENT,FETCH_EVENTS,
    FETCH_EVENT,EDIT_EVENT,

} from './types';
import dbUrl from '../apis/dbUrl';
import lessonsDB from '../apis/lessonsDB';
import history from '../history';


export const createEvent = (formValues) => async (dispacth) => {
    const response = await lessonsDB.post('/events', formValues);
    //console.log('create response', response)
    dispacth( { type: CREATE_EVENT, payload: response.data});
} 

export const fetchEvents = (text) => {
    return async dispacth => {
        const response = await lessonsDB.get(`${text}`);
        dispacth( { type: FETCH_EVENTS, payload: response.data});
        console.log("fetchevents action:", response)
    }
} 