import React from "react";
import { connect } from 'react-redux';
import {createUser} from '../../../actions';
import RegisterFom from './RegisterForm';
import loginImg from "./login.svg";

class Register extends React.Component {
  constructor(props) {
    super(props);
  }

  onSubmit=(registerValues)=>{
    console.log('registerValues', registerValues)
    this.props.createUser(registerValues)
  }

  render() {
    return (
      <div className="base-container" ref={this.props.containerRef}>
        <div className="header">Register</div>
        <div className="content">
          <div className="image">
            <img style={{maxWidth:'400px'}} 
            src={loginImg} />
          </div>
          <div className="form">
            <RegisterFom
            onSubmit={this.onSubmit}
            />
          </div>
        </div>
        <div className="footer">
          
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log('state:', state)
  
  return { 
      auth:state.auth,
      mentors:state.mentors
  }
}

export default connect(mapStateToProps, { createUser})(Register);