import React from 'react'
import { Field, reduxForm } from 'redux-form'
import validate from './validate'
import renderField from './renderField'

const WizardFormFirstPage = (props) => {
  const { handleSubmit } = props
  return (
    <form 
    className="ui form error"
    onSubmit={handleSubmit}>
      <Field name="firstName" type="text" component={renderField} label="First Name"/>
      <Field name="lastName" type="text" component={renderField} label="Last Name"/>
      <Field name="country" type="text" component={renderField} label="Country"/>
      <div>
        <button className="ui labeled icon button" type="submit" >
          Next <i class="right arrow icon"></i></button>
      </div>
    </form>
  ) 
}

export default reduxForm({
  form: 'menteewizard',                 // <------ same form name
  destroyOnUnmount: false,        // <------ preserve form data
  forceUnregisterOnUnmount: true,  // <------ unregister fields on unmount
  validate
})(WizardFormFirstPage)