import _ from 'lodash';
import { CREATE_MENTOR, 
    FETCH_MENTORS, FETCH_MENTORS_FAILED,
    FETCH_MENTOR,
    DELETE_MENTOR, EDIT_MENTOR } from '../actions/types';



    export default (state = {}, action) => {
        switch (action.type) {
            case FETCH_MENTORS:
                //_mapKeys turns array into an object taking 'id' as a key
                //it takes action.payload array and create an obj. 
                //  ... adds them into new object
                //return { ...state, mentors:action.payload}
                return { ...state, ..._.mapKeys(action.payload, 'mentorId')};
            case FETCH_MENTORS_FAILED:
                return{...state, error:action.payload }
            case FETCH_MENTOR:
                //key-value pair in state object, find [id]:its pair
                return { ...state, [action.payload.mentorId]:action.payload};
            case CREATE_MENTOR:
                return {...state, [action.payload.mentorId]:action.payload };
            case EDIT_MENTOR:
                return { ...state, [action.payload.mentorId]:action.payload};
            case DELETE_MENTOR:
                //for DELETE_MENTOR, action.payload is id itself (from action creator)
                //omit doesnt change the original state,  it create a new obj. insted
                return _.omit(state, action.payload)
            
            default:
                return state;
        }
    }
