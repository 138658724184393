import React, { Component } from 'react'
import MentorResponseForm from '../mentorForm/MentorResponseForm';

import { connect } from 'react-redux';
import _ from 'lodash'
import {createEvent} from '../../../../actions/calendarActions';
import {fetchLessons, editMentor, editLesson} from '../../../../actions';
import { Modal, ModalHeader, ModalBody} from "reactstrap";

import FullCalendar,{ formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction"

class MentorCalendar extends Component {
    state={
        showCreateModal:false,
        showEventModal:false,
        selectInfo:null,
        selectEvent:null,
        sun:null,
        mon:null,
        availabilities:[],
    }

    toggleCreate = () =>{
        this.setState({showCreateModal:!this.state.showCreateModal})
    }

    toggleEvent = () =>{
        this.setState({showEventModal:!this.state.showEventModal})
    }
    
    handleEventClick = (selectEvent) => {
        //ole.log(selectEvent)
        this.setState({
            selectEvent:selectEvent.event._def,
            showEventModal:true
        })
    }
    onResponseSubmit = (formValues) => {
        const id = this.state.selectEvent.publicId
        const text = `/lessons/${id}`
        const backgroundColor = formValues.acceptStatus === 'booked' ? 'green' : 'red'
        this.props.editLesson(text, {...formValues, backgroundColor})
        this.toggleEvent()
    }
    handleDateSelect = (selectInfo) =>{
        // console.log('selectInfo', selectInfo)
        // console.log('selectInfo', selectInfo.start)
        // console.log('selectInfo', selectInfo.start.getDay())
        // console.log('selectInfo', selectInfo.view.getCurrentData())
        
        let day= selectInfo.startStr.substring(0,4)
        let daysOfWeek = [selectInfo.start.getDay()]
        let startTime = selectInfo.startStr.substring(11,16)
        let endTime = selectInfo.endStr.substring(11,16)
        let businessHours={
            daysOfWeek,
            day,
            startTime,
            endTime
        }
        //console.log(businessHours)
        this.setState({availabilities:{'availabilities':[businessHours]}})
        this.setState({selectInfo})
        this.toggleCreate()
    }
    setAvailableTimes = () => {
        const {id} = this.props.mentor
        this.props.editMentor(id, this.state.availabilities)
        setTimeout(() => {
            this.toggleCreate()
        }, 800);
    }
    selectAllow = (selectInfo) => {
        const today = new Date()
        if(today < selectInfo.start){
            return true
        }else return false
    }
    renderEventModalBody = () => {
        const {selectEvent:{extendedProps:{details, acceptStatus, userId,  mentorFullName, explanation}}}= this.state
        return(
            <React.Fragment>
              <div className="ui middle aligned divided list">
                <div className="item">
                    <i className="plus icon"></i>
                    <div className="content">
                        <div className="header">Details</div>
                            <div className="description">{details}</div>
                    </div>
                </div>
                <div className="item">
                    <i className={`${acceptStatus === "booked" ? "check circle outline": acceptStatus === "denied" ? "calendar times icon": "exclamation"} icon`}></i>
                    <div className="content">
                        <div className="header">Status</div>
                            <div className="description">{acceptStatus}</div>
                    </div>
                </div>
                <div className="item">
                    <i className="user icon"></i>
                    <div className="content">
                        <div className="header">Mentor / Mentee</div>
                            <div className="description">{mentorFullName} / {userId} </div>
                    </div>
                </div>
                <div className="item">
                    <i className="bookmark outline icon"></i>
                    <div className="content">
                        <div className="header">Explanation</div>
                            <div className="description">{explanation}</div>
                    </div>
                </div>
                {this.state.selectEvent.extendedProps.mentorId === this.props.mentor.mentorId ?
                    <div className="item">
                    <MentorResponseForm 
                    onSubmit={this.onResponseSubmit} />
                </div>
                : null}
              </div>
      </React.Fragment>
        )
        
    }
    renderEventContent = (eventInfo) => {
        return (
          <>
            <b>{eventInfo.timeText}</b><br/>
            <i>{eventInfo.event.title}</i>
          </>
        )
    }
    refreshCalendar = () =>{
        const {userId}=this.props.mentor
        const today = new Date()
        const lastCheched = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        this.props.fetchLessons(`/lessons/?userId=${userId}`)
        this.setState({lastCheched})
    }

    render() {
        //console.log('state', this.state)
        console.log('props', this.props)
        const {selectEvent} = this.state
        const {mentor:{availabilities}, lessonsAsMentor, lessonsAsMentee} = this.props
        const newLessonsAsMentee = lessonsAsMentee.map(v => ({...v, color:"orange", textColor:"orange"}))
        //console.log('newArr', newArray)
        return (
            <React.Fragment>
            <div className="sixteen wide column">
                <div onClick={this.refreshCalendar} style={{cursor:'pointer'}} >
                    <div className="ui red ribbon label">
                        Refresh Calendar
                        <i className="sync icon"></i> 
                        {this.state.lastCheched}
                    </div>
                    
                </div>
            </div>
            <div className="sixteen wide column">
                <FullCalendar 
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    initialView='timeGridWeek'
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    nowIndicator={true}
                    expandRows={true}
                    businessHours={availabilities}
                    select={this.handleDateSelect}
                    selectAllow={this.selectAllow}
                    //events={lessonsAsMentor}
                    eventSources={
                        [lessonsAsMentor, newLessonsAsMentee]
                    }
                    eventClick={this.handleEventClick}

                    />
                </div>
                    <Modal isOpen={this.state.showCreateModal} toggle={this.toggleCreate}>
                        <ModalHeader>Schedule</ModalHeader>
                        <ModalBody>
                            <button className="ui button primary" onClick={this.setAvailableTimes} >Set Available Times</button>
                        </ModalBody>
                    </Modal>
                    {selectEvent ? 
                    <Modal isOpen={this.state.showEventModal} toggle={this.toggleEvent}>
                            <ModalHeader>{selectEvent.title}</ModalHeader>
                            <ModalBody>
                                {this.renderEventModalBody()}
                            </ModalBody>
                    </Modal>
                    : null }
                </React.Fragment>
        )
    }
}

export default  connect(null,
     {editMentor, editLesson, fetchLessons})(MentorCalendar)
