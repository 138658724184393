import React, { Component } from 'react'
import { Spinner } from 'reactstrap';
import {connect} from 'react-redux';
import {signIn, signOut} from '../../actions';

class GoogleAuth extends Component {
 
    componentDidMount() {
        //this gapi variable is available on window scopes
        //this code load up client portion of the library 
        //and it takes some time to download additional js code
        //so we need a "callback" when the process completes,
        //using clientId that we generated 
        //Scope's stuff is essentially talking about 
        //what different parts of the user's profile or the user account
        //that we want to get access to. we access email
        window.gapi.load('client:auth2', () => {
            window.gapi.client.init({
                clientId: 
                    "17674610005-7jjem9du180vqqmsvpavsqmomv7qbf12.apps.googleusercontent.com",
                    // '172994143447-rqugfp8r2g2nir360gmhj6d4djnao8qg.apps.googleusercontent.com',
                scope: 'email',
            }).then(()=> {
                //to figure out if user is signed in or not 
                this.auth = window.gapi.auth2.getAuthInstance();
                //console.log(this.auth)
                this.onAuthChange(this.auth.isSignedIn.get());
                //we can use listen() in _proto_ to see if the status is change
                this.auth.isSignedIn.listen(this.onAuthChange);
                //console.log('this.auth.isSignedIn.listen(this.onAuthChange)',this.auth.isSignedIn.listen(this.onAuthChange))
            });
        });
    }

    //to see auth. status change we can use listen() in _proto_
    onAuthChange= (isSignedIn) => {
        if(isSignedIn){
            const currentUserProfile = {
                userId:this.auth.currentUser.get().getBasicProfile().getId(),
                userName:this.auth.currentUser.get().getBasicProfile().getName(),
                userEmail:this.auth.currentUser.get().getBasicProfile().getEmail(),
                imageUrl:this.auth.currentUser.get().getBasicProfile().getImageUrl(),    
            }
            //console.log('current user', currentUserProfile)
            //to get the id of signed user and  pass it as a payload
            this.props.signIn(currentUserProfile);
        }else {
            this.props.signOut();
        }
    };
    
    onSignInClick = () => {
        this.auth.signIn()
    }
    onSignOutClick = () => {
        this.auth.signOut()
    }

    renderAuthButton() {
        if (this.props.isSignedIn === null ) {
            return (<Spinner size="sm" color="secondary" />)
        }else if (this.props.isSignedIn){
            return (
            <button onClick={this.onSignOutClick} className="ui red google button">
                <i className="google icon" />
                Sign Out
            </button>    
            )
        }else {
            return (
                <button onClick={this.onSignInClick} className="ui red google button">
                <i className="google icon" />
                Sign In
            </button> 
            )
        }
    }
    render() {
        return (
            <div>
                {this.renderAuthButton()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{ 
        isSignedIn: state.auth.isSignedIn,
    }
}

export default connect(
    mapStateToProps, 
    {signIn, signOut})
    (GoogleAuth)