import React,{useState} from 'react'

import { connect } from 'react-redux';
import {createEvent} from '../../../actions/calendarActions';
import {createLesson,deleteLesson} from '../../../actions';
import { Button, Modal, ModalHeader, ModalBody, Tooltip, Table, ModalFooter,
         ListGroupItem, ListGroup, ListGroupItemHeading
} from "reactstrap";
import LessonForm from '../../lessons/LessonForm';

import FullCalendar,{ formatDate, CalendarApi } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction"


class MentorCardCalendar extends React.Component {
    
    state={
        showCreateModal:false,
        showEventModal:false,
        selectInfo:null,
        selectEvent:null,
        validRange: {
            startTime: '2020-01-07',
            endTime: '2020-15-07'
        }
    }


     handleDateSelect = (selectInfo) => {
        //  console.log('selectInfo', selectInfo.view)
        //  console.log('selectInfo', selectInfo.view.getCurrentData())
        this.setState({selectInfo})
        this.toggleCreate()

        
    }
    selectAllow = (selectInfo) => {
        const today = new Date()
        if(today < selectInfo.start){
            return true
        }else return false
    }
     onCreateSubmit = (createValues) => {

        let  acceptStatus = "pending", backgroundColor="gray"
        const { currentUser:{userId}, mentor:{mentorId, firstName, lastName} } = this.props
        this.props.createLesson({
            mentorFullName:firstName + " " + lastName,
            title:createValues.title,
            details:createValues.details,
            start:this.state.selectInfo.startStr,
            //start:this.state.selectInfo.start,
            //end:this.state.selectInfo.end,
            end:this.state.selectInfo.endStr,
            acceptStatus,
            backgroundColor,
            userId,
            mentorId})
        this.toggleCreate()
        // console.log('create lesson', 
        //     {
        //         mentor:firstName + " " + lastName,
        //         title:createValues.title,
        //         details:createValues.details,
        //         start:this.state.selectInfo.startStr,
        //         end:this.state.selectInfo.endStr,
        //         acceptStatus,
        //         backgroundColor,
        //         userId,
        //         mentorId})

    } 
    renderDelete = () => {
        const id=this.state.selectEvent.publicId
        this.props.deleteLesson(id)
        setTimeout(() => {
            this.toggleEvent()
        }, 1000);
    }

    toggleCreate = () =>{
        this.setState({showCreateModal:!this.state.showCreateModal})
    }
    toggleEvent = () =>{
        this.setState({showEventModal:!this.state.showEventModal})
    }
    handleEventClick = (selectEvent) => {
        this.setState({
            selectEvent:selectEvent.event._def,
            showEventModal:true
        })
    }
    renderEventModalBody = () => {
        const {selectEvent:{extendedProps:{details, acceptStatus, userId,  mentorFullName, explanation}}}= this.state
        return(
            <React.Fragment>
              <div className="ui middle aligned divided list">
                <div className="item">
                    <i className="plus icon"></i>
                    <div className="content">
                        <div className="header">Details</div>
                            <div className="description">{details}</div>
                    </div>
                </div>
                <div className="item">
                    <i className={`${acceptStatus === "booked" ? "check circle outline": acceptStatus === "denied" ? "calendar times icon": "exclamation"} icon`}></i>
                    <div className="content">
                        <div className="header">Status</div>
                            <div className="description">{acceptStatus}</div>
                    </div>
                </div>
                <div className="item">
                    <i className="user icon"></i>
                    <div className="content">
                        <div className="header">Mentor / Mentee</div>
                            <div className="description">{mentorFullName} / {userId} </div>
                    </div>
                </div>
                <div className="item">
                    <i className="bookmark outline icon"></i>
                    <div className="content">
                        <div className="header">Explanation</div>
                            <div className="description">{explanation}</div>
                    </div>
                </div>
              </div>
      </React.Fragment>
        )
        
    }
    renderEventContent(eventInfo) {
        return (
          <>
            {/* <b>{eventInfo.timeText}</b><b> | </b> */}
            <i>{eventInfo.event.title}</i>
          </>
        )
      }

      
    render(){
        //console.log('calendar props', this.props)
        console.log('state', this.state)
        //console.log('lenght', this.state.businessHours.length)
        const {selectEvent}= this.state
        const {mentor:{availabilities}} = this.props
 
        return (
            <React.Fragment>
                {/* <div className="sixteen wide column">
                    <div onClick={this.refreshCalendar} style={{cursor:'pointer'}} >
                        <div className="ui red ribbon label">
                            Refresh Calendar
                            <i className="sync icon"></i> 
                            {this.state.lastCheched}
                        </div>
                        
                </div>
                </div> */}
            <div className="sixteen wide column">
            <FullCalendar
                    ref={this.calendarRef}
                    plugins={[ timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                    //   right: 'timeGridWeek'
                    }}
                    initialView="timeGridWeek"
                    editable={false}
                    selectable={true}
                    selectMirror={false}
                    dayMaxEvents={true}
                    allDaySlot={true}
                    slotEventOverlap={false}
                    eventOverlap={false}
                    selectOverlap={false}
                    slotDuration="01:00:00"
                    expandRows={true}
                    nowIndicator={true}
                    businessHours={availabilities}
                    selectConstraint={availabilities}
                    select={this.handleDateSelect}
                    selectAllow={this.selectAllow}                    
                    events={this.props.lessons}
                    eventClick={this.handleEventClick}
                    eventContent={this.renderEventContent}
                     />
                </div>
                
                     <Modal isOpen={this.state.showCreateModal} toggle={this.toggleCreate}>
                        <ModalHeader>Demand a Lesson</ModalHeader>
                        <ModalBody>
                            <LessonForm
                            onSubmit={this.onCreateSubmit}
                            />
                        </ModalBody>
                    </Modal>
                    {selectEvent ? 
                    <Modal isOpen={this.state.showEventModal} toggle={this.toggleEvent}>
                        <ModalHeader>{selectEvent.title}</ModalHeader>
                        <ModalBody>
                            {this.renderEventModalBody()}
                        </ModalBody>
                        <ModalFooter>
                            {this.props.currentUser.userId === selectEvent.extendedProps.userId ?
                                <Button color="danger" onClick={this.renderDelete}>Delete</Button>
                            : null}
                        </ModalFooter>
                    </Modal>
                    : null }
                    
            </React.Fragment>
        )
    }
    
    
}



export default connect(null, {createEvent, createLesson, deleteLesson})(MentorCardCalendar)