import React from 'react'
import _ from 'lodash';
import {Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {fetchMentors, signIn } from '../../actions'
import GoogleAuth from '../auth/GoogleAuth';
import {
    Button,
    Col,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
  } from 'reactstrap';

class Header extends React.Component {

    state= {
        isCollapseOpen:false
    }

    componentDidMount(){
        this.props.fetchMentors('/mentors')
    }

    renderUser = () => {
        const {isSignedIn}= this.props.auth
        if(!isSignedIn){
            return(
                <React.Fragment>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                            <i className="user outline  icon"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem>
                               <div> Sign In</div>
                            </DropdownItem>
                            <DropdownItem>
                                <div> Sign Up</div>
                            </DropdownItem>
                            <DropdownItem divider header/>
                            <DropdownItem>
                                <GoogleAuth/>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </React.Fragment>
            )
        }else if(isSignedIn){
            const {mentors, currentUser:{userId, userEmail}} = this.props
            const currentMentor = _.find(mentors, {googleInfo:{userId}})
            if(currentMentor && currentMentor.role === "mentor"){
                return (
                    <React.Fragment>
                    <UncontrolledDropdown nav inNavbar >
                        <DropdownToggle nav caret>
                            <i className="user  icon"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem>
                            <NavLink tag={Link} to={`/mentor/profile/${currentMentor.mentorId}`}>Mentor</NavLink>
                            </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>
                                <GoogleAuth/>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </React.Fragment>
                )
            }else {
                return(
                    <React.Fragment>
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret>
                                <i className="user icon"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem>
                                <NavLink tag={Link} to={`/mentee/profile/${userId}`}>Mentee</NavLink>
                                </DropdownItem>
                                <DropdownItem>
                                    <NavLink tag={Link}   to={`/mentor/apply/${userId}`} >Become a Mentor</NavLink>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>
                                    <GoogleAuth/>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </React.Fragment>
                )
            }

        }
    }

    toggleCollapse = () =>{
        this.setState({isCollapseOpen:!this.state.isCollapseOpen})
    }

    render () {
        return (
            <Col style={{backgroundColor:'#c1bc0d', minHeight:'100px'}} >
                <Navbar  dark  expand="md">
                    <NavbarBrand tag={Link} to="/" >Mentor Mentee</NavbarBrand>
                    <NavbarToggler onClick={this.toggleCollapse} />
                    <Collapse isOpen={this.state.isCollapseOpen} navbar>
                        <Nav className="mr-auto"  card>
                            <NavItem>
                                <NavLink tag={Link} to="" >How to?</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/allmentors" >Mentors</NavLink>
                            </NavItem>
                            <UncontrolledDropdown >
                            <DropdownToggle  nav caret>
                                Catagories
                            </DropdownToggle>
                            <DropdownMenu >
                                <DropdownItem>
                                <NavLink tag={Link} to="" >Technology/IT</NavLink>
                                </DropdownItem>
                                <DropdownItem>
                                <NavLink tag={Link} to="" >E-Commerce</NavLink>
                                </DropdownItem>
                                <DropdownItem>
                                <NavLink tag={Link} to="" >Entrepreneurship</NavLink>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem>
                                
                                </DropdownItem>
                            </DropdownMenu>
                            </UncontrolledDropdown>
                            
                        </Nav>
                        {this.renderUser()}
                    </Collapse>
                </Navbar>
            </Col>
        )
    }
}



const mapStateToProps = (state) => {
    //console.log('state:', state)
    
    return { 
        auth:state.auth,
        mentors:state.mentors,
        currentUser:state.auth.currentUser
    }
}

export default connect(mapStateToProps, { fetchMentors})(Header);