import React from 'react'

export const  MenteeProfileCard = (props) => {
    const {firstName, lastName,  country, googleInfo,age,interests  } = props.mentee
    return(
        <React.Fragment>
        <div className="ui red segment" style={{backgroundColor:'olive'}} >
            <div className="ui stackable two column grid">
                <div className="six wide column">
                <div  className="ui fluid card">
                    <img className="ui large image" src={googleInfo.imageUrl} />
                        <h2 className="header">{firstName} {lastName} </h2>
                    <div className="center aligned content">
                        {googleInfo.userEmail}
                    </div>
                    <div className="center aligned content">
                        <i className={`${country} flag`}></i> {country} 
                    </div>
                    <div className="center aligned content">
                        {age}
                    </div>
                </div>
                </div>
                <div className="ten wide column">
                    <div className="ui horizontal divider header">
                        <i className="info icon"></i> Interest
                    </div>
                        <div>
                            {interests.map((interest) =>{
                                return (
                                    <div className="ui label">{interest} </div>
                                )
                            })}
                        </div>
                    <div className="ui horizontal divider"></div>
                </div>
            </div>
        </div>
            </React.Fragment>
    )
}
