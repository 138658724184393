import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Provider} from 'react-redux'
import { createStore, applyMiddleware, compose} from 'redux';
import reducers from './reducers'
import reduxThunk from 'redux-thunk';
import Main from './component/Main';





const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers,
    composeEnhancers(applyMiddleware(reduxThunk))
    );

ReactDOM.render(
<Provider store={store}>
    <Main />
</Provider>,
document.getElementById('root'));


