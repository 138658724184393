import React from "react";
import {logIn} from '../../../actions';
import { connect } from 'react-redux';
import GoogleAuth from '../../auth/GoogleAuth';
import LoginForm from "./LoginForm";
import firebase from 'firebase/app';

import loginImg from "./login.svg";

class Login extends React.Component {
  constructor(props) {
    super(props);
  }

  onSubmit= (loginValues) => {
    console.log('loginValues', loginValues)
  }

  render() {
    return (
      <div className="base-container" ref={this.props.containerRef}>
        <div className="header">Login</div>
        <div className="content">
          <div className="image" >
            <img style={{maxWidth:'400px'}}
            src={loginImg} />
          </div>
          <div className="form">
            <LoginForm
            onSubmit={this.onSubmit}
            />
          </div>
        </div>
        <div className="footer">
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log('state:', state)
  
  return { 
      auth:state.auth,
      mentors:state.mentors
  }
}

export default connect(mapStateToProps, { logIn})(Login);
