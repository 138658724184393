import React from 'react'
import { Field, reduxForm } from 'redux-form';
import { Button, Form, FormGroup, label, input, FormText } from 'reactstrap';

const renderField = ({ input, label, type, placeholder, meta: { touched, error } }) => (
    <div  className= {`field ${error && touched ? 'error': '' }`}>
      <label>{label}</label>
      <div>
        <input  {...input} placeholder={placeholder} type={type}/>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  )
const MentorResponseForm = props => {
  const { handleSubmit, pristine, reset, submitting } = props
  return (
    <form 
    className="ui form error"
    onSubmit={handleSubmit}>
      <div className="field">

          <Field name="acceptStatus" component={renderField} type="radio" value="booked"  label="Accept"/>{' '}
          <Field name="acceptStatus" component={renderField} type="radio" value="denied" label="Deny"/>{' '}
          
          <label>Explanation</label>
          <Field name="explanation" component="textarea"/>
        </div>
        <button className="ui button primary" type="submit" disabled={pristine || submitting}>
          Submit
        </button>
        <button className="ui green button" type="button" disabled={pristine || submitting} onClick={reset}>
          Clear Values
        </button>
    </form>
  )
}

export default reduxForm({
  form: 'mentorResponseForm' // a unique identifier for this form
})(MentorResponseForm)