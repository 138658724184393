import React, { Component } from 'react'
import _ from 'lodash';
import { Row, Col, Table, Container, Badge,Button} from "reactstrap";
import { connect } from 'react-redux';
import {fetchMentees, createMentee,fetchLessons, editLesson} from '../../../actions';
import {fetchEvents, } from '../../../actions/calendarActions';
import MenteeCreate from './MenteeCreate';
import MenteeCardTabs from './MenteeCardTabs';
import MenteeCalendar from './MenteeCalendar';




class MenteeProfile extends Component {

    state= {
        lastCheched:null,
    }

    componentDidMount (){
        console.log('mentee didmount', this.props)
     const {userId}=this.props.match.params
    this.props.fetchMentees(`/mentees/?userId=${userId}`)
    // this.props.fetchEvents(`/events/?userId=${userId}`)
    this.props.fetchEvents('/events')
    this.props.fetchLessons(`/lessons/?userId=${userId}`)
    }
    componentDidUpdate(pP){
        const {auth:{currentUser, isSignedIn}}=this.props
        if(isSignedIn === false){
            this.props.history.push('/')
        }
    }



    renderMentee=()=>{
        const {mentee} =this.props
        return(
            <React.Fragment>

                <div className="container">
                    <div className="">
                        <MenteeCardTabs
                        mentee={mentee}
                        lessons={this.props.lessons}
                        />
                    </div>
                    <div className="ui horizontal divider header">
                        <i className="calendar icon"></i>
                        Calendar
                    </div>
                    <div >
                        <MenteeCalendar
                        lessons={this.props.lessons}
                        events={this.props.events}
                        mentee={mentee}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
        
    }

    render() {
        console.log('mentee profile props', this.props)
        const {mentee, auth:{isSignedIn}, eventsi, menteeLoading} =this.props
        if(!isSignedIn || menteeLoading){
            return(
                <div>Loading</div>
            )
        }else if(!mentee){
            return (
                <React.Fragment>
                    <MenteeCreate/>
                </React.Fragment>
            )
        }
        return(
            <React.Fragment>
                {this.renderMentee()}
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
   // console.log('mentee pofile state', state)
    const lessons = _.filter(state.lessons, {userId:ownProps.match.params.userId})
    //console.log('filter lessons', lessons)
    return {
        mentee:state.mentees[ownProps.match.params.userId],
        menteeLoading:state.mentees.loading,
        events:Object.values(state.events),
        auth:state.auth,
        lessons: Object.values(lessons),
    }

}
export default connect(mapStateToProps, 
    { fetchEvents,
        fetchMentees, createMentee,
        fetchLessons, editLesson})(MenteeProfile)
