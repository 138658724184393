import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import history from '../history';
import WebRTC from './webRTC/WebRTC';
import LessonsList from './lessons/LessonsList';
import Header from './layout/Header';
import LoginMain from './login/LoginMain';
import FbLogin from './login/fbLogin/FbLogin'
import MentorList from './user/mentor/MentorList';
import MentorCard from './user/mentor/MentorCard';
import MentorCreate from './user/mentor/MentorCreate';
import MenteeCreate from './user/mentee/MenteeCreate';
import Home from './Home';
import MentorProfile from './user/mentor/mentorProfile/MentorProfile';
import MenteeProfile from './user/mentee/MenteeProfile';
import MenteeProfileUpdate from './user/mentee/MenteeProfileUpdate';
import GoogleAuth from './auth/GoogleAuth';



const Main = () => {
  return (
    //we prevent BrowserRouter from creating its default history
    //instead we creat a internal history 
    <div>
      <Router >
          <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/login" component={LoginMain} />
            <Route path="/activelessons" exact component ={LessonsList}/>
            <Route path="/allmentors" component={MentorList} />
            <Route path="/room/:id"  component ={WebRTC}/>
            <Route path="/mentor/:mentorId" exact component={MentorCard} />
            <Route path="/mentee/create/menteeform" component={MenteeCreate} />
            <Route path="/mentor/apply/:userId" exact component={MentorCreate} />
            <Route path="/mentor/profile/:mentorId" exact component={MentorProfile} />
            <Route path="/mentee/profile/:userId" exact component={MenteeProfile} />
            <Route path="/mentee/profile/update/:userId" component={MenteeProfileUpdate}/>
          </Switch> 
      </Router> 
    </div>
  );
}
export default Main;
