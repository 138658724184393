import _ from 'lodash';
import { CREATE_LESSON, 
    FETCH_LESSONS,FETCH_LESSON,
    DELETE_LESSON, EDIT_LESSON } from '../actions/types';



    export default (state = {}, action) => {
        switch (action.type) {
            case FETCH_LESSONS:
                //_mapKeys turns array into an object taking 'id' as a key
                //it takes action.payload array and create an obj. 
                //  ... adds them into new object
                //return {...state, lessons:action.payload}
                return { ...state, ..._.mapKeys(action.payload, 'id')};
            case FETCH_LESSON:
                //key-value pair in state object, find [id]:its pair
                return { ...state, [action.payload.id]:action.payload};
            case CREATE_LESSON:
                return {...state, [action.payload.id]:action.payload};
            case EDIT_LESSON:
                return { ...state, [action.payload.id]:action.payload};
            case DELETE_LESSON:
                //for DELETE_LESSON, action.payload is id itself (from action creator)
                //omit doesnt change the original state,  it create a new obj. insted
                return _.omit(state, action.payload)
            
            default:
                return state;
        }
    }
