
//to avoid typo, we define types here
//nothing actually changes, but if there is a typo
//then there will be a warning at console
//because strings are now assinged to variables
export const SIGN_IN ='SIGN_IN';
export const SIGN_OUT ='SIGN_OUT';
export const CREATE_LESSON = 'CREATE_LESSON';
export const FETCH_LESSONS = 'FETCH_LESSONS';
export const FETCH_LESSON = 'FETCH_LESSON';
export const DELETE_LESSON = 'DELETE_LESSON';
export const EDIT_LESSON = 'EDIT_LESSON';
export const CREATE_MENTOR = 'CREATE_MENTOR';
export const FETCH_MENTORS = 'FETCH_MENTORS';
export const FETCH_MENTORS_FAILED = 'FETCH_MENTORS_FAILED';
export const FETCH_MENTOR = 'FETCH_MENTOR';
export const DELETE_MENTOR = 'DELETE_MENTOR';
export const EDIT_MENTOR = 'EDIT_MENTOR';
export const CREATE_MENTEE = 'CREATE_MENTEE';
export const FETCH_MENTEES = 'FETCH_MENTEES';
export const FETCH_MENTEE = 'FETCH_MENTEE';
export const DELETE_MENTEE = 'DELETE_MENTEE';
export const EDIT_MENTEE = 'EDIT_MENTEE';
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_IN_FAILED = "LOG_IN_FAILED";
export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOG_OUT_FAILED = "LOG_OUT_FAILED";
export const CREATE_EVENT = 'CREATE_EVENT';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_EVENT = 'FETCH_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';


