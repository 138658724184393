import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyCdZfkrG3H-p_RgbrvGzNf5y0AT8hqBdSA",
  authDomain: "mentormentee-a37a1.firebaseapp.com",
  databaseURL: "https://mentormentee-a37a1.firebaseio.com",
  projectId: "mentormentee-a37a1",
  storageBucket: "mentormentee-a37a1.appspot.com",
  messagingSenderId: "340802390689",
  appId: "1:340802390689:web:11ae850bea29ed56a0bee0",
  measurementId: "G-TJY5PZMTSB"
  };

  firebase.initializeApp(config)
  firebase.firestore()
  const storage = firebase.storage();
  //console.log('firebase', firebase)
  // console.log('firebase', firebase.auth)
  export  {
    storage, firebase as default
  }  