import React, { Component } from 'react'
import MenteeWizadForm from './menteeForm/menteeWizardForm/MenteeWizadForm';
import {Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { createMentee } from "../../../actions";

class MenteeCreate extends Component {
    state={
        isModalOpen:true
    }

    onSubmit = (formValues) => {
        console.log('menteeform', formValues)
        const googleInfo = this.props.currentUser
        const menteeId = (Math.random()*100000000000000000).toString()
        const {userId} = this.props.currentUser
        let role="mentee", isActive=true, isProfileComplete=true, appliedForMentor=false
        this.props.createMentee({...formValues, googleInfo, userId, menteeId, role, isActive, isProfileComplete,appliedForMentor })
        this.toggleModal()
    }
    toggleModal = () => {
        this.setState({isModalOpen:!this.state.isModalOpen})
    }
    
    render() {
        return (
            <React.Fragment>
                <Modal isOpen={this.state.isModalOpen} >
                    <ModalBody>
                    <MenteeWizadForm
                        onSubmit={this.onSubmit} 
                    />
                    </ModalBody>
                </Modal>
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => {
    //console.log('create state', state)
    return{ 
        currentUser: state.auth.currentUser,
    }
}

export default connect(mapStateToProps, {createMentee})(MenteeCreate)