import React from 'react'
import _ from "lodash";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {Col, Row, Button, Card, CardBody, CardImg, 
    CardTitle, CardText, CardSubtitle, 
    CardGroup, CardColumns, CardDeck, Container} from 'reactstrap';

import { fetchMentors} from '../../../actions';


class MentorList extends React.Component {
    
    componentDidMount ()  {
        this.props.fetchMentors('/mentors');
    }

    //we are defining a helper method to aviod mess in renderList
    //admin can delete and edit, if the mentorId matches with currentMentorId
    renderList (mentors) {
        console.log("mentors array:", mentors)
            //const userId = this.props.currentUser.userId
            //omit user from list if he is a mentor to prevent see himself on list
            //const omittedArray = _.omitBy(mentors, {userId})
            //console.log('new array', omittedArray)

            return  mentors.map(mentor => {
            const {firstName, lastName, currentTitle, id, mentorId, googleInfo} = mentor
                return (
                    <div className="column" key={id}>
                        <div  className="ui fluid card">
                        <Link to={`/mentor/${mentorId}`} className="content" style={{cursor:'pointer'}}>    
                            <img className="ui large image" src={googleInfo.imageUrl} />
                            
                                <h2 className="header">{firstName} {lastName} </h2>
                            </Link>
                            <div className="extra content">
                            </div>
                            <div className="center aligned content">
                                {currentTitle}
                            </div>
                            <div className="center aligned content">
                                <h3 className="meta"> Specialities</h3> 
                                <div className="ui image label">            
                                </div>
                            </div>
                            <div className="center aligned content">
                            </div>
                        </div>
                    </div>
                )
            })
    }

    
    render () {
        console.log('list props', this.props)
        const {mentors} = this.props
        if(!mentors){
            return(
                <div>Loading</div>
        )}else{
            return (
                <div>
                  <div className="ui container"style={{minHeight:'80vh'}} >
                      <h2>Mentors</h2>
                      <div className="ui grid">
                          <div className="doubling four column row">
                          {this.renderList(mentors)}
                      </div>
                  </div>
                  </div>
              </div>
            )  
        }
        
    }
}



const mapStateToProps = (state) => {
    //console.log('mentor state:', state)
    //since lessons is an obj. we turn it into an array with Object.values
    const mentors = _.filter(state.mentors, {role:"mentor"})
    return { 
        mentors: Object.values(mentors),
        //mentors:state.mentors,
        isSignedIn: state.auth.isSignedIn,
        currentUser:state.auth.currentUser,
    }
}

export default  connect(mapStateToProps, { fetchMentors})(MentorList)
