import {SIGN_IN, SIGN_OUT, 
    CREATE_LESSON, FETCH_LESSONS,FETCH_LESSON,
    DELETE_LESSON, EDIT_LESSON, 
    CREATE_MENTOR, DELETE_MENTOR,
    FETCH_MENTOR, 
    FETCH_MENTORS,FETCH_MENTORS_FAILED,
    EDIT_MENTOR,
    CREATE_MENTEE, DELETE_MENTEE,FETCH_MENTEES,
    FETCH_MENTEE,EDIT_MENTEE,
    LOG_IN_SUCCESS,LOG_IN_FAILED,
    LOG_OUT_SUCCESS,LOG_OUT_FAILED,
} from './types';

import alertify from 'alertifyjs'
import firebase from 'firebase/app';
import 'firebase/auth';
import config from '../apis/fbConfig'
import dbUrl from '../apis/dbUrl';
import lessonsDB from '../apis/lessonsDB';
import history from '../history';


//google auth
export const signIn = (currentUser) =>{
    return {
        type: SIGN_IN,
        payload: currentUser
    }
    
}

export const signOut = () => {
    return {
        type: SIGN_OUT
    }
} 

//login auth
export const logIn = (email, password,) => dispatch => {

    firebase.auth().signInWithEmailAndPassword(email, password).then((response)=>{
        dispatch({type:SIGN_IN, payload:response})
        
    })
    .catch((error) =>{
        dispatch({type:LOG_IN_FAILED, payload:error})
        //console.log('login error', error)
      });
}
export const createUser = (registerValues, history) => (dispatch) => {
    console.log('createUser', registerValues)
    const { email, password} = registerValues;
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(function() {

    return firebase.auth().createUserWithEmailAndPassword(email, password);
  })
  .catch(function(error) {
    // Handle Errors here.
    //console.log('register error', error)
  });

    
    // firebase.auth().createUserWithEmailAndPassword(email, password)
    // .then((response) =>{
    //     //console.log('create user', response)
    //     dispatch({type:SIGN_IN, payload:response})
        
          
    // })
    // .catch(function(error) {
    //     console.log('create user', error)
    //     dispatch({type:LOG_IN_FAILED, payload:error})
    // });
 
}
export const logOut = () => dispatch =>{
    firebase.auth().signOut().then(()=>{
        dispatch({type:LOG_OUT_SUCCESS, payload:'Log out successfull'})
    })
    .catch((error) =>{
        dispatch({type:LOG_OUT_FAILED, payload:error})
      });
      
}


//mentor's profile actions //

export const createMentor = (formValues) => async (dispacth, getState) => {
    //console.log('create mentor', formValues)
    const response = await dbUrl.post('/mentors', formValues);
    console.log('create response', response)
    dispacth( { type: CREATE_MENTOR, payload: response.data});
    
    //history.push('/');
} 
export const fetchMentors = (text) => {
    return async dispacth => {
        await dbUrl.get(`${text}`).then((response)=>{
            dispacth( { type: FETCH_MENTORS, payload: response.data})
        }).catch((err)=>{
            dispacth( { type: FETCH_MENTORS_FAILED, payload: err})
            //console.log('fetchmentors err', err)
        })
        
        //console.log("fetchmentors action:", response)
        
    }
} 
export const fetchMentor = (text) => async dispacth => {
    //console.log('fetchmentor invoked', text)
    const response = await dbUrl.get(`${text}`);
    dispacth( { type: FETCH_MENTOR, payload: response.data});
    //console.log("fetch mentor action:", response)
}
export const editMentor = (id, formValues) => async dispacth => {

    const response = await dbUrl.patch(`/mentors/${id}`, {...formValues});

    dispacth( { type: EDIT_MENTOR, payload: response.data})
    //history.push('/');
}
export const deleteMentor = (id) => async dispacth => {
    //no response to use here
    await dbUrl.delete(`/mentors/${id}`,);

    dispacth( { type: DELETE_MENTOR, payload:id});
    history.push('/');

}

// mentee's actions
//'s profile actions 
export const createMentee = (formValues) => async (dispacth, getState) => {
    //console.log('create mentor', formValues)
    const response = await dbUrl.post('/mentees', formValues);
    //console.log('create response', response)
    dispacth( { type: CREATE_MENTEE, payload: response.data});
    //history.push('/');
} 
export const fetchMentees = (text) => {
    return async dispacth => {
        const response = await dbUrl.get(`${text}`);
        dispacth( { type: FETCH_MENTEES, payload: response.data});
        console.log("fetchmentees action:", response)
    }
} 
export const fetchMentee = (text) => async dispacth => {
    //console.log('fetchmentee invoked', text)
    const response = await dbUrl.get(`${text}`);
    dispacth( { type: FETCH_MENTEE, payload: response.data});
    //console.log("fetch mentor action:", response)
}
export const editMentee = (id, formValues) => async dispacth => {
    console.log('edit mentee', {id, formValues})
    const response = await dbUrl.patch(`/mentees/${id}`, {...formValues});
    dispacth( { type: EDIT_MENTEE, payload: response.data})
}
export const deleteMentee = (id) => async dispacth => {
    //no response to use here
    await dbUrl.delete(`/mentee/${id}`,);

    dispacth( { type: DELETE_MENTEE, payload:id});
    history.push('/');

}



//lesson actions

export const createLesson = (formValues) => async (dispacth, getState) => {
    const createdAt = new Date()
    const response = await lessonsDB.post('/lessons', formValues);
    //console.log('create response', response)
    dispacth( { type: CREATE_LESSON, payload: response.data});
    
} 
export const fetchLessons = (text) => async (dispacth) => {
    // console.log('text', text)
    const response = await lessonsDB.get(`${text}`);
    dispacth( { type: FETCH_LESSONS, payload: response.data});
    //console.log("fetchLessons action:", response)
} 

export const fetchLesson = (id) => async dispacth => {
    const response = await lessonsDB.get(`/lessons/${id}`);
    dispacth( { type: FETCH_LESSON, payload: response.data});
    //console.log("fetchLesson action:", response.data)
}
export const editLesson = (text, formValues) => async dispacth => {

    const response = await lessonsDB.patch(`${text}`, {...formValues});
    dispacth( { type: EDIT_LESSON, payload: response.data})

}

export const deleteLesson = (id) => async dispacth => {
    //no response to use here
    //console.log('deleteLesson', id)
    await lessonsDB.delete(`/lessons/${id}`);

    dispacth( { type: DELETE_LESSON, payload:id});

    //if lesson acceptStatus === "booked" then sand an email to mentor

}


// !! firebase 
// export const editLesson = (formValues, lesson) => (dispatch, getState, {getFirestore}) => {
//     // console.log('edit form values', formValues)
//     console.log('edit lesson action', lesson)

//     const firestore = getFirestore();
//      firestore.update({ collection: 'lessons', doc:`${lesson.id}`},
//         {
//         ...formValues,
//         updateddAt: new Date(),

//         }  
//     ).then((response) => {
//         dispatch({type:EDIT_LESSON, payload:response});
//     }).catch((error) => {
//         console.log('edit error', error)
//     });
//     history.push('/')
// }


// export const deleteLesson = (lesson) => (dispatch, getState, {getFirestore}) => {
//     //console.log('delete action lesson', lesson)

//     const firestore = getFirestore();
//      firestore.delete({ collection: 'lessons', doc:`${lesson.id}`, }).then(() => {
//         dispatch({type:DELETE_LESSON});
//     }).catch((error) => {
//         console.log('lesson delete error', error)
//     });
//     history.push('/')
// }
// export const createLesson = (formValues) =>  async (dispatch, getState, {getFirestore}) => {
//     console.log('createlesson fromValues', formValues)
//     const firestore = getFirestore();
//     firestore.add(
//         {collection :'lessons' },
//         {
//         ...formValues,               
//         createdAt: new Date(),
//     }).then((response) => {
//         console.log('createlesson', response)
//         dispatch ( { type: CREATE_LESSON, payload:response })
//     }).catch((err) =>{
//         console.log('firestore create error', err)
//     })
//     history.push('/')
// }

// !! firebase 
// export const createProfile = (formValues, text) =>  async (dispatch, getState, {getFirestore}) => {
//     console.log('createlesson fromValues', formValues)
//     const firestore = getFirestore();
//     firestore.add(
//         {collection :text },
//         {
//         ...formValues,               
//         createdAt: new Date(),
//     }).then((response) => {
//         console.log('createlesson', response)
//         dispatch ( { type: CREATE_PROFILE, payload:response })
//     }).catch((err) =>{
//         console.log('firestore create error', err)
//     })
//     history.push('/')
// }
