import React from 'react'
import { Field, reduxForm, formValueSelector, Fields} from 'redux-form'
// import DatePicker, {
//     FieldDatePicker,
//     formatDates,
//     normalizeDates,
//   } from '../datepicker/DatePicker';
import { DateTimePicker} from 'react-widgets'
import moment from 'moment'
// import momentLocaliser from 'react-widgets-moment'
import DatePicker from '../datepicker/DatePicker'




const validate = values => {
  const errors = {}
  if (!values.title) {
    errors.title = 'Required'
  }
  if (!values.description) {
    errors.tdescriptiontle = 'Required'
  }
  if(!values.time){
      errors.time="Required"
  }
  if (values.time) {
    const hh = values.time.replace(/[^\d]/g, '').slice(0,2)
    const mm = values.time.replace(/[^\d]/g, '').slice(2,4)
    // console.log('hour',hh )
    // console.log('minute',mm )
    if(hh > 23 || mm > 59 ) {
        errors.time = "Must be a proper time period"
    }
  } 
  return errors
}

const renderField = ({ input, label, type, placeholder, meta: { touched, error } }) => (
  <div  className= {`field ${error && touched ? 'error': '' }`}>
    <label>{label}</label>
    <div>
      <input {...input} placeholder={placeholder} type={type}/>
      {touched && error && <span>{error}</span>}
    </div>
  </div>
)
 
const renderDateTimePicker = ({ input: { onChange, value }, showTime }) =>
  <DateTimePicker
    onChange={onChange}
    format="DD MMM YYYY"
    time={showTime}
    value={!value ? null : new Date(value)}
  />

const LessonForm = (props) => {
  //console.log('lesson props', props)
  const { handleSubmit, pristine, reset, submitting } = props
  return (
    <div className="ui two column centered grid">
      <div className="column">
      <form 
      className="ui form error"
      onSubmit={handleSubmit}>
          <Field name="title" type="text" component={renderField} label="Title"/>
          <Field name="details" type="text" component={renderField} label="Details"/>
          {/* <Field name="dateTime" component="select" label="Select Availability">
            {props.availability.map(option =>{
              return(
              <option>{option.date} | {option.time}</option>
              )
              
            })}
          </Field>
          <FieldDatePicker name="date" label="Date" placeholder="YYYY-MM-DD"/>
          <Field name="time" component={renderField} type="text" placeholder="HH:MM" label="Time" normalize={normalizeTime} /> */}
          {/* <DateTimePicker name="dateTime" showTime  label="Select Date" /> */}
        <div>
          <button className="ui button primary" type="submit" disabled={submitting}>Submit</button>
          <button className="ui green button" type="button" disabled={pristine || submitting} onClick={reset}>Clear Values</button>
        </div>
      </form>
      </div>
    </div>
  )
}
// const normalizeTime = (value, previousValue) => {
//     if (!value) {
//       return value
//     }
//     const onlyNums = value.replace(/[^\d]/g, '')
//     if (!previousValue || value.length > previousValue.length) {
//       // typing forward
//       if (onlyNums.length === 2) {
//         return onlyNums + ':'
//       }
//       if (onlyNums.length === 4) {
//         return onlyNums.slice(0, 2) + ':' + onlyNums.slice(2)
//       }
//     }
//     if (onlyNums.length <= 2) {
//       return onlyNums
//     }
//     if (onlyNums.length <= 4) {
//       return onlyNums.slice(0, 2) + ':' + onlyNums.slice(2)
//     }
//     return onlyNums.slice(0, 2) + ':' + onlyNums.slice(2, 4)
//   }
  

export default reduxForm({
  form: 'lessonform',  // a unique identifier for this form
  validate                 // <--- validation function given to redux-form
})(LessonForm)