//strings are now assinged to variables
//if a typo, we see an error mesage
import {SIGN_IN, SIGN_OUT} from '../actions/types';


//in order to default the value may create an initialiser
//capitalised obj. means "do not change under any circumstances"
const INITIAL_STATE = {
    isSignedIn: null,
    currentUser: null,
    loading:true,
}
export default  (state= INITIAL_STATE, action) => {
    switch(action.type) {
        case SIGN_IN:
            return {...state, isSignedIn:true, loading:false, currentUser:action.payload};
        case SIGN_OUT:
            return {...state, isSignedIn:false, currentUser:null};
        default:
            return state;

    }
}